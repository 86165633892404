<template>
        <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card strpied-tabled-with-hover">
                                <div class="card-header ">
                                    <h4 class="card-title">Todos los ebooks </h4>
                                    <p>Podrás crear, listar y editar ebooks 
                                    <router-link to="/crear-articulo">
                                        <p class="float-right btn btn-info btn-fill"><b> + Nuevo ebook</b></p>
                                    </router-link></p>
                                    
                                </div>
                                <div class="card-body table-full-width table-responsive p-0 p-md-5">
                                    <div class="preloader mt-3" v-if="!articles"></div>
                                    <table class="table table-hover" :class="!articles ? 'd-none' : ''">
                                        <thead>
                                            <th>ID</th>
                                            <th>Título</th>
                                            <th>Autor</th>
                                            <th>Estado</th>
                                            <th>Acción</th>
                                        </thead>
                                        <tbody>
                                            <tr v-for="article in articles" :key="article.id">
                                                <td>{{article.id}}</td>
                                                <td>{{article.title}}</td>
                                                <td>{{article.author}}</td>
                                                <td><div class="form-check ml-2"><label class="form-check-label"><input class="form-check-input" type="checkbox" @click="statusArticle({id: article.id, status: article.status == 1 ? 0 : 1})" :checked="article.status == 1 ? true : false"><span class="form-check-sign"></span></label></div></td>
                                                <td><router-link :to="`/editar-articulo/${article.id}`"><i class="fa fa-edit text-info mr-2 lead" style="padding-top: 1.3px; margin-left: 10px; cursor: pointer; margin-top: 7px"></i></router-link><!--<i class="fa fa-times text-danger lead" style="cursor: pointer; margin-top: 6.7px" @click="deleteArticle(article.id)"></i>--></td>
                                            </tr>
                                          
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                       
                    </div>
        </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
data(){
        return {
            
        }
    },
methods:{
    ...mapActions('articles',['getArticles', 'statusArticle','deleteArticle'])
},
created(){
    
    this.getArticles()
    
},
computed:{
    ...mapState('articles', ['articles']),
    statusPreloader(){
        !this.articles ? 'd-none' : ''
    }
    
}
}
</script>

<style>

</style>