import { createRouter, createWebHistory } from 'vue-router'
import Articles from '../views/Articles.vue'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Articles,
    meta:{protected: true}
  },
  {
    path: '/usuario/:id',
    name: 'User',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/User.vue'),
    meta:{protected: true}
  },
  {
    path: '/articulos',
    name: 'Articles',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Articles.vue'),
    meta:{protected: true}
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/crear-articulo',
    name: 'CreateArticle',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CreateArticle.vue'),
    meta:{protected: true}
  },
  {
    path: '/editar-articulo/:id',
    name: 'EditeArticle',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/EditeArticle.vue'),
    meta:{protected: true}
  },
  {
    path: '/categorias',
    name: 'Categories',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Categories.vue'),
    meta:{protected: true}
  },
  {
    path: '/editar-categoria/:id',
    name: 'EditeCategory',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/EditeCategory.vue'),
    meta:{protected: true}
  },
  {
    path: '/crear-categoria',
    name: 'CreateCategory',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CreateCategory.vue'),
    meta:{protected: true}
  },
  {
    path: '/cupones',
    name: 'Coupons',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Coupons.vue'),
    meta:{protected: true}
  },
  {
    path: '/crear-cupon',
    name: 'CreateCoupon',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CreateCoupon.vue'),
    meta:{protected: true}
  },
  {
    path: '/editar-cupon/:id',
    name: 'EditeCoupon',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/EditeCoupon.vue'),
    meta:{protected: true}
  },
  {
    path: '/ordenes/:error?',
    name: 'Orders',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Orders.vue'),
    meta:{protected: true}
  },
  {
    path: '/orden/:id',
    name: 'Order',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Order.vue'),
    meta:{protected: true}
  },
  {
    path: '/usuarios',
    name: 'Users',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Users.vue'),
    meta:{protected: true}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if(to.meta.protected){
    if(store.getters.userIdentity){
      next()
    }else{
      next('/login')
    }
  }else{
    next()
  }
})

export default router
