<template>
              <footer class="footer">
                <div class="container-fluid">
                    <nav>
                        <ul class="footer-menu d-none">
                            <li>
                                <a href="#">
                                    Home
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    Company
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    Portfolio
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    Blog
                                </a>
                            </li>
                        </ul>
                        <p class="copyright text-center">
                            © IADPI Ebooks - Desarrollo de Devti Argentina - V 1.3

                            <!--Un desarrollo de <a href="https://luisalbanese.com.ar" target="_blank">Luis Albanese</a>-->
                        </p>
                    </nav>
                </div>
            </footer>

</template>

<script>
export default {

}
</script>

<style>

</style>