import router from '../router'
import store from '../store'

export default{
    namespaced: true,
    state:{
        coupons: null,
        coupon: null,
        error: null
    },
    mutations:{
        setCoupons(state, payload){
            state.coupons = payload
        },
        setCoupon(state, payload){
            state.coupon = payload
        }
    },
    actions:{
        async getCoupons({commit}){
            try {
                const res = await fetch(`${process.env.VUE_APP_API_URL}/coupon/getall/page=1`)
                const couponsDB = await res.json()
                commit('setCoupons', couponsDB.data)
                return couponsDB.data
            } catch (error) {
                console.log(error)
            }
        },
        async createCoupon({commit}, coupon){
            try {

                const { token, ref_token } = JSON.parse(localStorage.getItem('userIadpi'))

                const res = await fetch(`${process.env.VUE_APP_API_URL}/coupon/create`,{
                    method: 'post',
                    headers:{
                        'Authorization': token,
                        'Ref-Token': ref_token
                    },
                    body: JSON.stringify({name: coupon.name, percentage: coupon.percentage, expiration_date: coupon.expiration_date, lot: coupon.lot, max_purchase: coupon.max_purchase})
                })

                if(res.status === 401){
                    store.state.user = null
                    localStorage.removeItem('userIadpi')
                    demo.showNotification('top', 'right', 4, 'La sesión ha expirado, vuelva a loguearse')
                    router.push('/login')
                    return false
                }

                const couponDB = await res.json()
                if(couponDB.status == 'error'){
                    commit('setError', couponDB.message)
                    demo.showNotification('top', 'right', 4, couponDB.message)
                    return false
                }

                if(couponDB.status == 'ok'){
                    commit('setCoupon', coupon)
                    demo.showNotification('top', 'right', 2, 'Cupón creado correctamente!')
                    router.push('/cupones')
                    return  true
                }

               // console.log(couponDB)

            } catch (error) {
                console.log(error)
                
            }

        },
        async editeCoupon({commit}, coupon){
            try {
                const { token, ref_token } = JSON.parse(localStorage.getItem('userIadpi'))
                const res = await fetch(`${process.env.VUE_APP_API_URL}/coupon/edit`,{
                    method: 'put',
                    headers:{
                        'Authorization': token,
                        'Ref-Token': ref_token
                    },
                    body:
                        JSON.stringify({ id: coupon.id, name: coupon.name, percentage: coupon.percentage, expiration_date: coupon.expiration_date, lot: coupon.lot, max_purchase: coupon.max_purchase })
                    
                })

                if(res.status === 401){
                    store.state.user = null
                    localStorage.removeItem('userIadpi')
                    demo.showNotification('top', 'right', 4, 'La sesión ha expirado, vuelva a loguearse')
                    router.push('/login')
                    return false
                }

                const couponDB = await res.json()
                if(couponDB.status == 'error'){
                    commit('setError', couponDB.message)
                    demo.showNotification('top', 'right', 4, couponDB.message)
                    return false
                }

                if(couponDB.status == 'ok'){
                    commit('setCoupon', coupon)
                    demo.showNotification('top', 'right', 2, 'Cupón editado correctamente!')
                    router.push('/cupones')
                    return  true
                }


            } catch (error) {
                console.log(error)
            }
        }

    }
}