import router from '../router'
import store from '../store'

export default{
    namespaced: true,
    state:{
        categories: null,
        category: null,
        error: null
    },
    mutations:{
        setCategories(state, payload){
            state.categories = payload
        },
        setCategory(state, payload){
            state.category = payload
        }
    },
    actions:{
        async getCategories({commit}){
            try {
                const res = await fetch(`${process.env.VUE_APP_API_URL}/category/getall/page=1`)
                const categoriesDB = await res.json()
                commit('setCategories', categoriesDB.data)
                return categoriesDB.data
            } catch (error) {
                console.log(error)
            }
        },
        async createCategory({commit}, category){
            try {

                const { token, ref_token } = JSON.parse(localStorage.getItem('userIadpi'))

                const res = await fetch(`${process.env.VUE_APP_API_URL}/category/create`,{
                    method: 'post',
                    headers:{
                        'Authorization': token,
                        'Ref-Token': ref_token
                    },
                    body: JSON.stringify({name: category})
                })

                if(res.status === 401){
                    store.state.user = null
                    localStorage.removeItem('userIadpi')
                    demo.showNotification('top', 'right', 4, 'La sesión ha expirado, vuelva a loguearse')
                    router.push('/login')
                    return false
                }

                const categoryDB = await res.json()
                if(categoryDB.status == 'error'){
                    commit('setError', categoryDB.message)
                    demo.showNotification('top', 'right', 4, categoryDB.message)
                    return false
                }

                if(categoryDB.status == 'ok'){
                    commit('setCategory', category)
                    demo.showNotification('top', 'right', 2, 'Categoría creada correctamente!')
                    router.push('/categorias')
                    return  true
                }

                console.log(categoryDB)

            } catch (error) {
                console.log(error)
                
            }

        },
        async editeCategory({commit}, category){
            try {
                const { token, ref_token } = JSON.parse(localStorage.getItem('userIadpi'))
                const res = await fetch(`${process.env.VUE_APP_API_URL}/category/edit`,{
                    method: 'put',
                    headers:{
                        'Authorization': token,
                        'Ref-Token': ref_token
                    },
                    body:
                        JSON.stringify({ id: category.id, name: category.name })
                    
                })

                if(res.status === 401){
                    store.state.user = null
                    localStorage.removeItem('userIadpi')
                    demo.showNotification('top', 'right', 4, 'La sesión ha expirado, vuelva a loguearse')
                    router.push('/login')
                    return false
                }

                const categoryDB = await res.json()
                if(categoryDB.status == 'error'){
                    commit('setError', categoryDB.message)
                    demo.showNotification('top', 'right', 4, categoryDB.message)
                    return false
                }

                if(categoryDB.status == 'ok'){
                    commit('setCategory', category)
                    demo.showNotification('top', 'right', 2, 'Categoría editada correctamente!')
                    router.push('/categorias')
                    return  true
                }


            } catch (error) {
                console.log(error)
            }
        }

    }
}