import router from '../router'
import store from '../store'

export default{
    namespaced: true,
    state:{
        users: null,
        user: null,
        error: null
    },
    mutations:{
        setUsers(state, payload){
            state.users = payload
        },
        setUser(state, payload){
            state.user = payload
        }
    },
    actions:{
        async getUser({commit}, user){
            try {
                const { token, ref_token } = JSON.parse(localStorage.getItem('userIadpi'))
                const res = await fetch(`${process.env.VUE_APP_API_URL}/user/getone/${user.id}`,{
                    method: 'get',
                    headers:{
                        'Authorization': token,
                        'Ref-Token': ref_token
                    }
                })
                const usersDB = await res.json()
                commit('setUser', usersDB)
               
            } catch (error) {
                console.log(error)
            }
        },
        async getUsers({commit}){
            try {
                const { token, ref_token } = JSON.parse(localStorage.getItem('userIadpi'))
                const res = await fetch(`${process.env.VUE_APP_API_URL}/user/getall/page=1`,{
                    method: 'get',
                    headers:{
                        'Authorization': token,
                        'Ref-Token': ref_token
                    }
                })
                const usersDB = await res.json()
                commit('setUsers', usersDB.data)
            } catch (error) {
                console.log(error)
            }
        },
        async editUser({commit}, user){
            try {
                const { token, ref_token } = JSON.parse(localStorage.getItem('userIadpi'))
                const res = await fetch(`${process.env.VUE_APP_API_URL}/user/edit`,{
                    method: 'put',
                    headers:{
                        'Authorization': token,
                        'Ref-Token': ref_token
                    },
                    body:
                        JSON.stringify({ id: user.id, name: user.name, lastname: user.lastname, dni_cuit: user.dni_cuit, adress: user.adress, location: user.location, city_id: user.city_id, country_id: user.country_id, phone: user.phone, role: user.role, status: user.status })
                    
                })

                if(res.status === 401){
                    store.state.user = null
                    localStorage.removeItem('userIadpi')
                    demo.showNotification('top', 'right', 4, 'La sesión ha expirado, vuelva a loguearse')
                    router.push('/login')
                    return false
                }

                const userDB = await res.json()
                if(userDB.status == 'error'){
                    commit('setError', userDB.message)
                    demo.showNotification('top', 'right', 4, userDB.message)
                    return false
                }

                if(userDB.status == 'ok'){
                    demo.showNotification('top', 'right', 2, 'Usuario editado correctamente!')
                    router.push('/usuarios')
                    return  true
                }


            } catch (error) {
                console.log(error)
            }
        }

    }
}