import { createStore } from 'vuex'
import router from '../router'
import articles from '../modules/articles'
import categories from '../modules/categories'
import coupons from '../modules/coupons'
import orders from '../modules/orders'
import users from '../modules/users'


export default createStore({
  state: {
    user: null,
    file: ''
  },
  mutations: {
    setUser(state, payload){
      state.user = payload
    }
  },
  actions: {
    async login({commit}, user){
      try {

        const res = await fetch(`${process.env.VUE_APP_API_URL}/auth/login`,{
          method: 'post',
          body:JSON.stringify({
              email : user.email,
              password : user.password
          })
        })

        const userDB = await res.json()
        if(userDB.status == "ok"){
          commit('setUser', userDB)
          console.log(userDB)
          router.push('/')
          localStorage.setItem('userIadpi', JSON.stringify(userDB))
        
        }else{
        
          return false
        }

      } catch (error) {
        console.log(error)
      }

    },
    async validateLogin({commit}){
      if(localStorage.getItem('userIadpi')){
        commit('setUser', JSON.parse(localStorage.getItem('userIadpi')))
      }else{
        return commit('setUser', null)
      }
    },
    logout({commit}){
      commit('setUser', null)
      localStorage.removeItem('userIadpi')
      router.push('/login')
    }
  },
  getters:{
    userIdentity(state){
      const status = state.user.role == 1 ? true : false
      return status
      //return !!state.user
    }
  },
  modules: {
    articles, 
    categories,
    coupons,
    orders,
    users
  }
})
