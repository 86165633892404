import router from '../router'
import store from '../store'

export default{
    namespaced: true,
    state:{
        orders: null,
        order: null,
        ebooksOrder:  null,
        error: null
    },
    mutations:{
        setOrders(state, payload){
            state.orders = payload
        },
        setOrder(state, payload){
            state.order = payload
        },
        setEbooksOrder(state, payload){
            state.ebooksOrder = payload
        },

    },
    actions:{
        async getOrders({commit}){
            try {
                const { token, ref_token } = JSON.parse(localStorage.getItem('userIadpi'))
                const res = await fetch(`${process.env.VUE_APP_API_URL}/order/getall/page=1`,{
                    method: 'get',
                    headers:{
                        'Authorization': token,
                        'Ref-Token': ref_token
                    }
                })
                const ordersDB = await res.json()
                commit('setOrders', ordersDB.data)
            } catch (error) {
                console.log(error)
            }
        },
        async getOrder({commit}, order){
            try {
                const { token, ref_token } = JSON.parse(localStorage.getItem('userIadpi'))
                const res = await fetch(`${process.env.VUE_APP_API_URL}/order/getone/${order.id}`,{
                    method: 'get',
                    headers:{
                        'Authorization': token,
                        'Ref-Token': ref_token
                    }
                })
                const orderDB = await res.json()
                commit('setOrder', orderDB)
                console.log(orderDB)
            } catch (error) {
                console.log(error)
            }
        },
        async getEbooksOrder({commit}, order){
            try {
                const { token, ref_token } = JSON.parse(localStorage.getItem('userIadpi'))
                const res = await fetch(`${process.env.VUE_APP_API_URL}/order/getebooksorder/${order.id}`,{
                    method: 'get',
                    headers:{
                        'Authorization': token,
                        'Ref-Token': ref_token
                    }
                })
                const ebooksOrderDB = await res.json()
                commit('setEbooksOrder', ebooksOrderDB)
                console.log(ebooksOrderDB)
            } catch (error) {
                console.log(error)
            }
        },
        async editeOrder({commit}, order){
            try {
                const { token, ref_token } = JSON.parse(localStorage.getItem('userIadpi'))
                const res = await fetch(`${process.env.VUE_APP_API_URL}/coupon/edit`,{
                    method: 'put',
                    headers:{
                        'Authorization': token,
                        'Ref-Token': ref_token
                    },
                    body:
                        JSON.stringify({ id: order.id, name: order.name, exp: order.exp })
                    
                })

                if(res.status === 401){
                    store.state.user = null
                    localStorage.removeItem('userIadpi')
                    demo.showNotification('top', 'right', 4, 'La sesión ha expirado, vuelva a loguearse')
                    router.push('/login')
                    return false
                }

                const orderDB = await res.json()
                if(orderDB.status == 'error'){
                    commit('setError', orderDB.message)
                    demo.showNotification('top', 'right', 4, orderDB.message)
                    return false
                }

                if(orderDB.status == 'ok'){
                    commit('setOrder', order)
                    demo.showNotification('top', 'right', 2, 'Categoría editada correctamente!')
                    router.push('/ordenes')
                    return  true
                }


            } catch (error) {
                console.log(error)
            }
        },

        async completeOrder({commit}, order){
            try {
                if (!window.confirm("Seguro que deseas completar la orden?")) {
                    return false;
                }
                const { token, ref_token } = JSON.parse(localStorage.getItem('userIadpi'))
                const res = await fetch(`${process.env.VUE_APP_API_URL}/order/complete/${order.id}`,{
                    method: 'get',
                    headers:{
                        'Authorization': token,
                        'Ref-Token': ref_token
                    }
                })
                const dataRes = await res.json()
                if(dataRes.status == 'ok'){
                    demo.showNotification('top', 'right', 2, 'Orden completada y ebooks asignados correctamente al usuario.')
                    router.push('/ordenes')
                    return  true
                }else{
                    demo.showNotification('top', 'right', 4, 'La order ya fue completada anteriormente, no es necesaria esta acción.')
                    router.push('/ordenes')
                    return  true
                }
            } catch (error) {
                console.log(error)
            }
        },

    }
}