<template>
<div class="wrapper">
        <SideBarComponent />
    <div class="main-panel">
        <MainPanelComponent />
        <div class="content">
          <router-view/>
        </div>
        <FooterComponent />
    </div>
</div>
</template>
<script>

import { mapActions, mapState } from 'vuex'
import SideBarComponent from './components/SideBarComponent'
import MainPanelComponent from './components/MainPanelComponent'
import FooterComponent from './components/FooterComponent'
import router from  './router'
export default {
  components:{
    SideBarComponent,
    MainPanelComponent,
    FooterComponent
  },
  computed:{
    ...mapState(['user'])
  },
  methods:{
    ...mapActions(['validateLogin'])
    
  },
  created(){
    this.validateLogin()
  }
}
</script>
<style>
.preloader {
  width: 70px;
  height: 70px;
  border: 10px solid #eee;
  border-top: 10px solid #666;
  border-radius: 50%;
  animation-name: girar;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  margin: 0 auto;

}

@keyframes girar {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>