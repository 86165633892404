<template>
    <div class="sidebar" data-image="/assets/img/sidebar-4.jpg">
            <!--
        Tip 1: You can change the color of the sidebar using: data-color="purple | blue | green | orange | red"

        Tip 2: you can also add an image using data-image tag
        -->
        <div class="sidebar-wrapper">
                <div class="logo">
                    <a href="#" class="simple-text text-center">
                        <img src="/assets/img/logo.png" alt="logo" width="150">
                    </a>
                </div>
                <ul class="nav">
                    <div v-if="user">
                    <li v-if="user.role == 5">
                        <router-link class="nav-link" to="/">
                            <i class="nc-icon nc-chart-pie-35"></i>
                            <p>Dashboard</p>
                        </router-link>
                    </li>
                    <li v-if="user.role == 1">
                        <router-link to="/articulos" class="nav-link">
                            <i class="nc-icon nc-cloud-upload-94"></i>
                            <p>Gestionar Ebooks</p>
                        </router-link>
                    </li>
                    <li v-if="user.role == 1">
                        <router-link to="/categorias" class="nav-link">
                            <i class="nc-icon nc-bullet-list-67"></i>
                            <p>Gestionar Categorías</p>
                        </router-link>
                    </li>
                    <li v-if="user.role == 1">
                        <router-link to="/cupones" class="nav-link">
                            <i class="nc-icon nc-tag-content"></i>
                            <p>Gestionar Cupones</p>
                        </router-link>
                    </li>
                    <li v-if="user.role == 1">
                        <router-link to="/usuarios" class="nav-link">
                            <i class="nc-icon nc-single-02"></i>
                            <p>Gestionar Usuarios</p>
                        </router-link>
                    </li>
                    <li v-if="user.role == 1">
                        <router-link to="/ordenes" class="nav-link">
                            <i class="nc-icon nc-cart-simple"></i>
                            <p>Gestionar Ordenes</p>
                        </router-link>
                    </li>
                    <li v-if="user.role == 10">
                        <router-link to="/user" class="nav-link">
                            <i class="nc-icon nc-circle-09"></i>
                            <p>Mi perfil</p>
                        </router-link>
                    </li>
                    <li class="nav-item active-pro">
                        <a @click.prevent="logout" class="nav-link" href="#">
                            <i class="nc-icon nc-button-power mr-1"></i>
                            <p class="ml-2">Cerrar sesión</p>
                        </a>
                    </li>
                    </div>
                </ul>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    computed:{
        ...mapState(['preloader', 'user'])
    },
    methods:{
        ...mapActions(['logout'])
    }

}
</script>

<style>
.nav a.router-link-exact-active {
  background-color: rgba(255, 255, 255, 0.15);
}
</style>