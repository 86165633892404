<template>
    <!-- Navbar -->
            <nav class="navbar navbar-expand-lg " color-on-scroll="500">
                <div class="container-fluid">
                    <div class="d-lg-none"><i class="nc-icon nc-stre-left"></i><span class=""> <i class="nc-icon nc-tap-01"></i> Desliza para ver el menú</span></div>
                    <!--<button href="" class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-bar burger-lines"></span>
                        <span class="navbar-toggler-bar burger-lines"></span>
                        <span class="navbar-toggler-bar burger-lines"></span>
                    </button>-->
                    
                </div>
            </nav>
            <!-- End Navbar -->
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    computed:{
        ...mapState(['preloader'])
    },
    methods:{
        ...mapActions(['logout'])
    }

}
</script>

<style>

</style>