import router from '../router'
import store from '../store'

export default{
    namespaced: true,
    state:{
        articles: null,
        article: null,
        error: '',
        message: ''
    },
    mutations:{
        setArticles(state, payload){
            state.articles = payload
        },
        setArticle(state, payload){
            state.article = payload
        },
        setImage(state, payload){
            state.article.img_uri = payload
        },
        setFile(state, payload){
            state.article.pdf_uri = payload
        },
        setError(state, payload){
            state.error = payload
        },
        setMessage(state, payload){
            state.message = payload
        },
        delete(state, payload){
            state.articles = state.articles.filter(article => article.id !== payload)
        }
    },
    actions:{
        async getArticles({commit}){
            try {
                const { token, ref_token } = JSON.parse(localStorage.getItem('userIadpi'))
                
                const res = await fetch(`${process.env.VUE_APP_API_URL}/ebook/getalladmin/page=1`,{
                    method: 'get',
                    headers:{
                            'Authorization': token,
                            'Ref-Token': ref_token
                    }
                })

                if(res.status === 401){
                    store.state.user = null
                    localStorage.removeItem('userIadpi')
                    demo.showNotification('top', 'right', 4, 'La sesión ha expirado, vuelva a loguearse')
                    router.push('/login')
                    return 
                }

                const articlesDB = await res.json()
                commit('setArticles', articlesDB.data)

            } catch (error) {
                console.log(error)
            }
        },
        async getArticlesUser({commit}){
            try {
                const { token, ref_token } = JSON.parse(localStorage.getItem('userIadpi'))
                
                const res = await fetch(`${process.env.VUE_APP_API_URL}/ebook/getalluser`,{
                    method: 'get',
                    headers:{
                            'Authorization': token,
                            'Ref-Token': ref_token
                    }
                })

                if(res.status === 401){
                    store.state.user = null
                    localStorage.removeItem('userIadpi')
                    demo.showNotification('top', 'right', 4, 'La sesión ha expirado, vuelva a loguearse')
                    router.push('/login')
                    return 
                }

                const articlesDB = await res.json()
                console.log(articlesDB)
                commit('setArticles', articlesDB.data)

            } catch (error) {
                console.log(error)
            }
        },
        async createArticle({commit}, article){

            try {
                const { token, ref_token } = JSON.parse(localStorage.getItem('userIadpi'))

                const res = await fetch(`${process.env.VUE_APP_API_URL}/ebook/create`,{
                    method: 'post',
                    body: JSON.stringify(article),
                    headers:{
                        'Authorization': token,
                        'Ref-Token': ref_token
                    }
                })

                if(res.status === 401){
                    store.state.user = null
                    localStorage.removeItem('userIadpi')
                    demo.showNotification('top', 'right', 4, 'La sesión ha expirado, vuelva a loguearse')
                    router.push('/login')
                    return false
                }

                const articleDB = await res.json()
                if(articleDB.status == 'error'){
                    commit('setError', articleDB.message)
                    demo.showNotification('top', 'right', 4, articleDB.message)
                    return false
                }

                if(articleDB.status == 'ok'){
                    commit('setArticle', article)
                    demo.showNotification('top', 'right', 2, 'Articulo creado correctamente!')
                    return {status: true, articleId: articleDB.id}
                }

                //commit('setArticle', articleDB)
            } catch (error) {
                console.log(error)
            }
        },
        async getOne({commit}, id){
            try {
                const { token, ref_token } = JSON.parse(localStorage.getItem('userIadpi'))

                const res = await fetch(`${process.env.VUE_APP_API_URL}/ebook/getoneadmin/${id}`,{
                    method: 'get',
                    headers:{
                        'Authorization': token,
                        'Ref-Token': ref_token
                    }
                })

                if(res.status === 401){
                    store.state.user = null
                    localStorage.removeItem('userIadpi')
                    demo.showNotification('top', 'right', 4, 'La sesión ha expirado, vuelva a loguearse')
                    router.push('/login')
                    return false
                }

                const articleDB = await res.json()
                commit('setArticle', articleDB)

            } catch (error) {
                console.log(error)
            }
        },  
        async getOneUser({commit}, id){
            try {
                const { token, ref_token } = JSON.parse(localStorage.getItem('userIadpi'))

                const res = await fetch(`${process.env.VUE_APP_API_URL}/ebook/getoneuser/${id}`,{
                    method: 'get',
                    headers:{
                        'Authorization': token,
                        'Ref-Token': ref_token
                    }
                })

                if(res.status === 401){
                    store.state.user = null
                    localStorage.removeItem('userIadpi')
                    demo.showNotification('top', 'right', 4, 'La sesión ha expirado, vuelva a loguearse')
                    router.push('/login')
                    return false
                }

                const articleDB = await res.json()
                commit('setArticle', articleDB)

            } catch (error) {
                console.log(error)
            }
        },         
        async editeArticle({commit}, article){
            try {
                const { token, ref_token } = JSON.parse(localStorage.getItem('userIadpi'))
                const res = await fetch(`${process.env.VUE_APP_API_URL}/ebook/edit`,{
                    method: 'put',
                    headers:{
                        'Authorization': token,
                        'Ref-Token': ref_token
                    },
                    body: JSON.stringify({
                            id: article.id,
                            title: article.title,
                            isbn: article.isbn,
                            author: article.author,
                            edition: article.edition,
                            category_id: article.category_id,
                            description: article.description,
                            price: article.price,
                            image: article.image,
                            discount: article.discount
                    })
                    
                })

                if(res.status === 401){
                    store.state.user = null
                    localStorage.removeItem('userIadpi')
                    demo.showNotification('top', 'right', 4, 'La sesión ha expirado, vuelva a loguearse')
                    router.push('/login')
                    return false
                }

                const articleDB = await res.json()

                if(articleDB.status == 'error'){
                    commit('setError', 'Error al intentar crear el ebook')
                    demo.showNotification('top', 'right', 4, 'Error al intentar crear el ebook')
                    return false
                }

                if(articleDB.status == 'ok'){
                    commit('setArticle', article)
                    demo.showNotification('top', 'right', 2, 'Articulo editado correctamente!')
                    return {status: true}
                }

            } catch (error) {
                console.log(error)
            }
        },
        async statusArticle({commit}, article){
            try {
                const { token, ref_token } = JSON.parse(localStorage.getItem('userIadpi'))

                const res = await fetch(`${process.env.VUE_APP_API_URL}/ebook/disable`,{
                    method: 'put',
                    headers:{
                        'Authorization': token,
                        'Ref-Token': ref_token
                    },
                    body: JSON.stringify({
                        id: article.id,
                        status: article.status
                        
                    })
                })

                if(res.status === 401){
                    store.state.user = null
                    localStorage.removeItem('userIadpi')
                    demo.showNotification('top', 'right', 4, 'La sesión ha expirado, vuelva a loguearse')
                    router.push('/login')
                    return false
                }

                const statusArticle = await res.json()
                if(statusArticle.status == 'ok'){
                    demo.showNotification('top', 'right', 2, 'El estado del ebook se ha editado correctamente!')
                    return true
                }

                demo.showNotification('top', 'right', 4, 'Hubo un problema al editar el estado del ebook!')
            }
            catch(error){
                console.log(error)
            }
        },
        async deleteArticle({commit}, id){
        if(window.confirm('Seguro que desea eliminar el artículo?')){
            
                const {token: jwt} = JSON.parse(localStorage.getItem('user'))

            const res = await fetch(`${process.env.VUE_APP_API_URL}/articles/delete`,{
                method: 'delete',
                headers:{
                    TOKEN: jwt
                },
                body:JSON.stringify({
                    id: id
                })
            })

            if(res.status == 401){
                store.state.user = null
                localStorage.removeItem('user')
                demo.showNotification('top','right', 4, 'La sesión ha expirado, vuelva a loguearse')
                router.push('/login')
                return false
            }

            const deleteArticle = await res.json()
            if(deleteArticle.status == 'OK'){
                demo.showNotification('top', 'right', 2, 'El artículo ha sido eliminado!')
                commit('delete', id)
                return true
            }

            demo.showNotification('top', 'right', 4, 'Hubo un problema al eliminar el artículo!')
        }
        },
        async uploadImage({commit}, upload){

            try {
                const { token, ref_token } = JSON.parse(localStorage.getItem('userIadpi'))

                const imagen = new FormData()
                imagen.append('image', upload.file, upload.file.name)
                const articleId = upload.articleId
                const res = await fetch(`${process.env.VUE_APP_API_URL}/ebook/uploadimage/${articleId}`,{
                    method: 'post',
                    headers:{
                        'Authorization': token,
                        'Ref-Token': ref_token
                    },
                    body: imagen

                })
               const resUpload = await res.json()
                if(resUpload.status == 'error'){
                    return demo.showNotification('top', 'right', 4, `La imágen no pudo subirse ${resUpload.message} Se asignará una imágen por defecto luego podrá modificarla.`)
                }

                if(resUpload.status == true){
                    commit('setImage', resUpload.path)
                    return {path: resUpload.path}
                }
            } catch (error) {
                console.log(error)
            }

        },
        async uploadPdf({commit}, upload){

            try {
                const { token, ref_token } = JSON.parse(localStorage.getItem('userIadpi'))

                const file = new FormData()
                file.append('file', upload.file, upload.file.name)
                const articleId = upload.articleId
                const res = await fetch(`${process.env.VUE_APP_API_URL}/ebook/uploadfile/${articleId}`,{
                    method: 'post',
                    headers:{
                        'Authorization': token,
                        'Ref-Token': ref_token
                    },
                    body: file

                })
               const resUpload = await res.json()
                if(resUpload.status == 'error'){
                    return demo.showNotification('top', 'right', 4, `El pdf no pudo subirse ${resUpload.message}`)
                }

                if(resUpload.status == true){
                    commit('setFile', resUpload.path)
                    return {path: resUpload.path}
                }
            } catch (error) {
                console.log(error)
            }

        }

    },
    getters:{

    }
}